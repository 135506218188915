@import "./../theme/variables.scss";

body {
  background: map-get($colors, offWhite);
  font-family: map-get($fonts, SFRegular);
  font-weight: 500;
  color: map-get($colors, black);
  font-size: 14px;
  padding: 0;
  margin: 0;
}

::selection {
	background: rgba(0, 0, 0, 0.75);
	color: white;
}

h1, h2, h3, h4, h5, h6, p, a, ul, li, span, strong {
  font-weight: 500;
  margin: 0;
  padding: 0;
}

a, a:hover, a:active, a:visited {
  color: map-get($colors, black);
  text-decoration: none;
}

// MAIN 
main {
  
}