$colors: (
  primary:         #000000,
  secondary:       #999999,
  tertiary:        #2C3E50,
  borderColor:     #EDEDED,
  black:           #000000,
  white:           #FFFFFF,
  offWhite:        #F7F8F8,
  grey:            #999999,
  red:             #E74C3C,
);

$iconNames: (
  heart:             '\e06e',
);

$fonts: (
  SFUltraLight:    'SanFrancisco-UltraLight',
  SFThin:          'SanFrancisco-Thin',
  SFRegular:       'SanFrancisco-Regular',
  SFMedium:        'SanFrancisco-Medium',
  SFSemibold:      'SanFrancisco-Semibold',
  SFBold:          'SanFrancisco-Bold',
  OmnesRegular:    'Omnes-Regular',
  OmnesMedium:     'Omnes-Medium',
  OmnesExtraLight: 'Omnes-ExtraLight',
  OmnesLight:      'Omnes-Light',
  OmnesSemibold:   'Omnes-Semibold',
  LigatureSymbols: 'LigatureSymbols',
  Inconsolata:     'Inconsolata',
);

@each $iconName, $uniCode in $iconNames {
  .icon-#{$iconName}::before,
  .icon-#{$iconName}::before,
  .icon-#{$iconName}-outline::before,
  .icon-#{$iconName}-outline::before {
    content: $uniCode;
    font-family: "LigatureSymbols", sans-serif;
    font-weight: normal;
  }
  .has-text {
    padding-right: 10px;
  }
}

@font-face {
  font-family: 'LigatureSymbols';
  src: url('/assets/fonts/LigatureSymbols/LigatureSymbols-2.11.eot');
  src: url('/assets/fonts/LigatureSymbols/LigatureSymbols-2.11.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/LigatureSymbols/LigatureSymbols-2.11.woff') format('woff'),
       url('/assets/fonts/LigatureSymbols/LigatureSymbols-2.11.ttf') format('truetype'),
       url('/assets/fonts/LigatureSymbols/LigatureSymbols-2.11.svg#LigatureSymbols') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family:'Omnes-Regular';
  src: url('/assets/fonts/Omnes/omnesregularwebfont.eot');
  src: url('/assets/fonts/Omnes/omnesregularwebfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Omnes/omnesregularwebfont.woff') format('woff'),
       url('/assets/fonts/Omnes/omnesregularwebfont.ttf') format('truetype'),
       url('/assets/fonts/Omnes/omnesregularwebfont.svg#Omnes-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family:'Omnes-ExtraLight';
  src: url('/assets/fonts/Omnes/omnes_extralightwebfont.eot');
  src: url('/assets/fonts/Omnes/omnes_extralightwebfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Omnes/omnes_extralightwebfont.woff') format('woff'),
       url('/assets/fonts/Omnes/omnes_extralightwebfont.ttf') format('truetype'),
       url('/assets/fonts/Omnes/omnes_extralightwebfont.svg#Omnes-ExtraLight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family:'Omnes-Light';
  src: url('/assets/fonts/Omnes/omnes_lightwebfont.eot');
  src: url('/assets/fonts/Omnes/omnes_lightwebfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Omnes/omnes_lightwebfont.woff') format('woff'),
       url('/assets/fonts/Omnes/omnes_lightwebfont.ttf') format('truetype'),
       url('/assets/fonts/Omnes/omnes_lightwebfont.svg#Omnes-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family:'Omnes-Medium';
  src: url('/assets/fonts/Omnes/omnes_mediumwebfont.eot');
  src: url('/assets/fonts/Omnes/omnes_mediumwebfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Omnes/omnes_mediumwebfont.woff') format('woff'),
       url('/assets/fonts/Omnes/omnes_mediumwebfont.ttf') format('truetype'),
       url('/assets/fonts/Omnes/omnes_mediumwebfont.svg#Omnes-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family:'Omnes-Semibold';
  src: url('/assets/fonts/Omnes/omnessemiboldwebfont.eot');
  src: url('/assets/fonts/Omnes/omnessemiboldwebfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Omnes/omnessemiboldwebfont.woff') format('woff'),
       url('/assets/fonts/Omnes/omnessemiboldwebfont.ttf') format('truetype'),
       url('/assets/fonts/Omnes/omnessemiboldwebfont.svg#Omnes-Semibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SanFrancisco-UltraLight";
  font-weight: 100;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-ultralight-webfont.woff");
}
@font-face {
  font-family: "SanFrancisco-Thin";
  font-weight: 200;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-thin-webfont.woff");
}
@font-face {
  font-family: "SanFrancisco-Regular";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}
@font-face {
  font-family: "SanFrancisco-Medium";
  font-weight: 500;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff");
}
@font-face {
  font-family: "SanFrancisco-Semibold";
  font-weight: 600;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff");
}
@font-face {
  font-family: "SanFrancisco-Bold";
  font-weight: 700;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
}
@font-face{
  font-family:'Inconsolata';
  src: url('/assets/fonts/Inconsolata/inconsolata-medium-500.eot');
  src: url('/assets/fonts/Inconsolata/inconsolata-medium-500.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Inconsolata/inconsolata-medium-500.woff') format('woff'),
       url('/assets/fonts/Inconsolata/inconsolata-medium-500.ttf') format('truetype'),
       url('/assets/fonts/Inconsolata/inconsolata-medium-500.svg#Inconsolata') format('svg');
  font-weight: normal;
  font-style: normal;
}